
import { useRoute } from "vue-router"
import { defineComponent } from "vue"
import { getList } from "./script/constants"
export default defineComponent({
  props: {
    key: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const LIST = getList()
    const route = useRoute()

    const listObject = LIST.find(({ key }) => key === route.params.key)!
    return {
      listObject
    }
  }
})
